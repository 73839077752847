import { UploadOutlined } from '@ant-design/icons'
import { Button, Form, Input, message, Space, Upload, UploadFile } from 'antd'
import React from 'react'

import { useForm } from 'antd/lib/form/Form'

import { uploadApi } from 'feature/core/api/upload'
import { storeApi } from 'feature/store/api'
import { AddressInput } from 'feature/ui/components/form/AddressInput'
import {
  defaultImageUploadFile,
  normalizeUploadFormItem,
} from 'feature/ui/util/upload'

import { Store } from 'type/client/store'
import { Address } from 'type/client/type'
import { S3ResourceType } from 'type/client/upload'

interface UpdateStoreFormProps {
  name: string
  address: Address
  logoImageFiles: UploadFile[]
  phoneNumber: string | null
  reason: string
}

const UpdateStoreForm = ({
  store,
  onFormFinish,
}: {
  store: Store
  onFormFinish: () => void
}) => {
  const [form] = useForm()

  const initialFormValues: UpdateStoreFormProps = {
    name: store.name,
    address: store.address,
    logoImageFiles:
      store.logoImagePath && store.logoImageUrl
        ? [defaultImageUploadFile(store.logoImagePath, store.logoImageUrl)]
        : [],
    phoneNumber: store.phoneNumber,
    reason: '',
  }

  const handleSubmitBtnClick = async (values: UpdateStoreFormProps) => {
    try {
      let logoImagePath = null
      if (values.logoImageFiles.length > 0) {
        logoImagePath = await uploadApi.uploadTempFile(
          S3ResourceType.STORE_LOGO_IMAGE,
          values.logoImageFiles[0].originFileObj!
        )
      }

      await storeApi.update(store.id, {
        name: values.name,
        address: values.address,
        phoneNumber: values.phoneNumber,
        logoImagePath: logoImagePath,
        reason: values.reason,
      })

      onFormFinish()
    } catch (e) {
      message.error('가게 수정에 실패했습니다.')
    }
  }

  return (
    <Form
      form={form}
      layout={'vertical'}
      onFinish={handleSubmitBtnClick}
      initialValues={initialFormValues}
    >
      <Form.Item
        label="가게명"
        name="name"
        rules={[
          {
            required: true,
            message: '가게명을 입력해주세요',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="주소"
        name="address"
        rules={[
          {
            required: true,
            message: '주소를 입력해주세요',
          },
        ]}
      >
        <AddressInput />
      </Form.Item>
      <Form.Item
        label="로고 이미지"
        name="logoImageFiles"
        valuePropName="fileList"
        getValueFromEvent={normalizeUploadFormItem}
      >
        <Upload
          maxCount={1}
          beforeUpload={() => false}
          listType="picture"
        >
          <Button icon={<UploadOutlined />}>업로드</Button>
        </Upload>
      </Form.Item>
      <Form.Item
        label="전화번호"
        name="phoneNumber"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="사유"
        name="reason"
        rules={[
          {
            required: true,
            message: '사유를 입력해주세요',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button
            className={'mt-3 w-[100px]'}
            type={'primary'}
            htmlType={'submit'}
          >
            수정
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default UpdateStoreForm
