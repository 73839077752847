import { EditFilled } from '@ant-design/icons'
import { App, Modal } from 'antd'
import React from 'react'

import CreateStoreForm from 'feature/store/componont/CreateStoreForm'

const useCreateStoreFormModal = () => {
  const { modal } = App.useApp()

  const handleFormFinish = (onChangeStore: () => void) => {
    return () => {
      Modal.destroyAll()
      onChangeStore()
    }
  }

  const open = (onChangeStore: () => void) =>
    modal.confirm({
      title: '가게 생성',
      icon: <EditFilled />,
      content: (
        <CreateStoreForm onFormFinish={handleFormFinish(onChangeStore)} />
      ),
      width: 600,
      footer: null,
      closable: true,
      maskClosable: true,
      centered: true,
    })

  return {
    open,
  }
}

export default useCreateStoreFormModal
