import { RcFile } from 'antd/lib/upload'

import { adminAxios } from 'config/adminClientConfig'

import {
  CreateStorePayload,
  DeactivateStorePayload,
  Store,
  UpdateStorePayload,
} from 'type/client/store'
import { Page } from 'type/client/type'

const getAll = async (page: number, keyword: string) => {
  return adminAxios.get<Page<Store>>(`/stores?page=${page}&keyword=${keyword}`)
}

const create = async (params: CreateStorePayload) => {
  return adminAxios.post<number>('/stores', params)
}

const update = async (id: number, params: UpdateStorePayload) => {
  return adminAxios.put<void>(`/stores/${id}`, params)
}

const deactivate = async (id: number, params: DeactivateStorePayload) => {
  return adminAxios.delete<void>(`/stores/${id}`, {
    data: params,
  })
}

const uploadLogoImage = async (id: number, file: RcFile) => {
  const formData = new FormData()
  formData.append('file', file)

  return adminAxios.post<string>(`/stores/${id}/upload-logo-image`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const storeApi = {
  getAll,
  uploadLogoImage,
  create,
  update,
  deactivate,
}
