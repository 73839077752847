import { EditFilled } from '@ant-design/icons'
import { App, Modal } from 'antd'
import React from 'react'

import DeactivateNoticeForm from 'feature/notice/componont/DeactivateNoticeForm'

import { Notice } from 'type/client/notice'

const useDeactivateNoticeFormModal = () => {
  const { modal } = App.useApp()

  const handleFormFinish = (onChangeNotice: () => void) => {
    return () => {
      Modal.destroyAll()
      onChangeNotice()
    }
  }

  const open = (notice: Notice, onChangeNotice: () => void) =>
    modal.confirm({
      title: '공지사항 비활성화',
      icon: <EditFilled />,
      content: (
        <DeactivateNoticeForm
          notice={notice}
          onFormFinish={handleFormFinish(onChangeNotice)}
        />
      ),
      footer: null,
      closable: true,
      maskClosable: true,
      centered: true,
    })

  return {
    open,
  }
}

export default useDeactivateNoticeFormModal
