export const NoticeType = {
  NOTICE: 'NOTICE',
  EVENT: 'EVENT',
} as const
export type NoticeType = (typeof NoticeType)[keyof typeof NoticeType]

export interface Notice {
  id: number
  uuid: string
  type: NoticeType
  title: string
  content: string
  createdAt: string
  modifiedAt: string
  deactivatedAt: string
}

export interface WriteNoticePayload {
  type: NoticeType
  title: string
  content: string
}

export interface EditNoticePayload {
  type: NoticeType
  title: string
  content: string
}

export interface DeactivateNoticePayload {
  reason: string
}
