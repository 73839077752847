import { Table, Tag } from 'antd'
import React from 'react'

import { ColumnsType } from 'antd/es/table'

import useDeactivateLoungeFormModal from 'feature/lounge/hook/useDeactivateLoungeFormModal'
import useUpdateLoungeFormModal from 'feature/lounge/hook/useUpdateLoungeFormModal'

import { Lounge } from 'type/client/lounge'

const LoungeTable = ({
  isLoading,
  lounges,
  loadLounges,
}: {
  isLoading: boolean
  lounges: Lounge[]
  loadLounges: () => void
}) => {
  const { open: openUpdateModal } = useUpdateLoungeFormModal()
  const { open: openDeactivateModal } = useDeactivateLoungeFormModal()

  const handleDeactivateATagClick = (lounge: Lounge) => {
    return () => {
      openDeactivateModal(lounge, loadLounges)
    }
  }

  const handleEditATagClick = (lounge: Lounge) => {
    return () => {
      openUpdateModal(lounge, loadLounges)
    }
  }

  const columns: ColumnsType<Lounge> = [
    {
      title: '상태',
      key: 'status',
      render: (_, record) => {
        const isActivate = !record.deactivatedAt
        const status = isActivate ? '활성화' : '비활성화'

        return <Tag color={isActivate ? 'green' : 'red'}>{status}</Tag>
      },
    },
    {
      title: '라운지명',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'UUID',
      dataIndex: 'uuid',
      key: 'uuid',
    },
    {
      title: '생성일',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: '비활성화일',
      dataIndex: 'deactivatedAt',
      key: 'deactivatedAt',
    },
    {
      title: '',
      width: 300,
      key: 'action',
      render: (_, record) => (
        <div className={'flex gap-3'}>
          <a onClick={handleEditATagClick(record)}>수정</a>
          <a onClick={handleDeactivateATagClick(record)}>비활성화</a>
        </div>
      ),
    },
  ]

  return (
    <Table
      loading={isLoading}
      rowKey={'id'}
      dataSource={lounges}
      columns={columns}
      bordered={true}
      pagination={false}
    />
  )
}

export default LoungeTable
