import { Input, message, Table } from 'antd'
import React, { useEffect, useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import { ColumnsType } from 'antd/es/table'

import { addressApi } from 'feature/core/api/address'
import { addressToString } from 'feature/core/util/address'

import { Address } from 'type/client/type'

import type { GetComponentProps } from 'rc-table/lib/interface'

interface AddressInputProps {
  id?: string
  value?: Address
  onChange?: (value: Address) => void
}

export const AddressInput = ({ id, value, onChange }: AddressInputProps) => {
  const [query, setQuery] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)
  const [searchedAddresses, setSearchedAddresses] = React.useState<Address[]>(
    []
  )
  const [selectedAddress, setSelectedAddress] = React.useState<Address | null>(
    value ?? null
  )

  useEffect(() => {
    if (selectedAddress) {
      triggerChange(selectedAddress)
    }
  }, [selectedAddress])

  const triggerChange = (changedValue: Address) => {
    if (onChange) {
      onChange(changedValue)
    }
  }

  const handleSearchBtnClick = async () => {
    if (!query) {
      message.error('검색어를 입력해주세요.')
      return
    }

    try {
      setIsLoading(true)
      const response = await addressApi.search(query)
      setSearchedAddresses(response)
    } catch (e) {
      message.error('주소 검색에 실패했습니다.')
      setSearchedAddresses([])
    } finally {
      setIsLoading(false)
    }
  }

  const handleSearcherChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
  }

  const handleTableRow: GetComponentProps<Address> = (
    record: Address,
    _index: number | undefined
  ) => {
    return {
      onClick: _event => setSelectedAddress(record),
    }
  }

  const handleDetailAddressChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedAddress({
      ...selectedAddress,
      detail: e.target.value,
    } as Address)
  }

  const fullAddress = useMemo(
    () => (!selectedAddress ? '' : addressToString(selectedAddress)),
    [selectedAddress]
  )

  const columns: ColumnsType<Address> = [
    {
      title: '우편번호',
      dataIndex: 'zipCode',
      key: 'zipCode',
      width: 100,
    },
    {
      title: '주소',
      dataIndex: 'address',
      key: 'address',
      render: (_, record) => addressToString(record),
    },
  ]

  return (
    <div
      id={id}
      className={'flex flex-col gap-3'}
    >
      <div className={'flex gap-3'}>
        <Input.Search
          onChange={handleSearcherChange}
          onSearch={handleSearchBtnClick}
          enterButton
        />
      </div>
      <Table
        rowKey={() => uuid()}
        loading={isLoading}
        onRow={handleTableRow}
        dataSource={searchedAddresses}
        columns={columns}
        bordered={true}
        pagination={false}
        size={'small'}
        scroll={{ y: 150 }}
      />
      <div>상세 주소 입력</div>
      <Input
        readOnly={!selectedAddress}
        onChange={handleDetailAddressChange}
        value={selectedAddress?.detail ?? ''}
      />
      <div className={'font-bold'}>결과 주소</div>
      <Input
        readOnly
        value={fullAddress}
      />
    </div>
  )
}
