import { Button, Col, Input, message, Row } from 'antd'
import React, { useEffect, useState } from 'react'

import { storeApi } from 'feature/store/api'
import StoreTable from 'feature/store/componont/StoreTable'
import useCreateStoreFormModal from 'feature/store/hook/useCreateStoreFormModal'

import { Store } from 'type/client/store'
import { emptyPage, Page } from 'type/client/type'

const StorePage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [stores, setStores] = useState<Page<Store>>(emptyPage)
  const [query, setQuery] = useState<string>('')

  const { open: openCreateModal } = useCreateStoreFormModal()

  useEffect(() => {
    loadFirstPage()
  }, [])

  const loadStores = async (page: number) => {
    setIsLoading(true)
    try {
      const response = await storeApi.getAll(page, query)
      setStores(response)
    } catch (error) {
      message.error('가게 목록을 불러오는데 실패했습니다.')
    } finally {
      setIsLoading(false)
    }
  }

  const loadFirstPage = () => loadStores(0)

  const handleCreateBtnClick = () => {
    openCreateModal(loadFirstPage)
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
  }

  const handleSearch = () => loadFirstPage()

  return (
    <>
      <Row>
        <div className={'text-2xl font-bold mb-3'}>가게 관리</div>
      </Row>
      <Row className={'mb-6'}>
        <Col span={8}>
          <Input.Search
            onSearch={handleSearch}
            onChange={handleSearchChange}
            placeholder={'가게명을 검색하세요'}
          />
        </Col>
      </Row>
      <Row className={'mb-3'}>
        <Col span={24}>
          <Button
            type={'primary'}
            onClick={handleCreateBtnClick}
          >
            가게 추가
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <StoreTable
            isLoading={isLoading}
            stores={stores}
            loadStores={loadStores}
          />
        </Col>
      </Row>
    </>
  )
}

export default StorePage
