import { Button, Col, Empty, message, Row } from 'antd'
import React, { useEffect, useState } from 'react'

import { termApi } from 'feature/terms/api'
import TermsCodeSelector from 'feature/terms/component/TermsCodeSelector'
import TermHistoryList from 'feature/terms/component/TermsHistory'
import UpdateTermsForm from 'feature/terms/component/UpdateTermsForm'
import useCreateTermFormModal from 'feature/terms/hook/useCreateTermFormModal'

import { Terms } from 'type/client/terms'

const TermPage = () => {
  const [codes, setCodes] = useState<string[]>([]) // [1]
  const [selectedCode, setSelectedCode] = useState<string | null>(null)
  const [termList, setTermList] = useState<Terms[]>([])

  const { open: openCreateModal } = useCreateTermFormModal(setSelectedCode)

  useEffect(() => {
    const loadAllCodes = async () => {
      try {
        const codes = await termApi.getAllCodes()
        setCodes(codes)
      } catch (e) {
        message.error('약관 코드를 불러오는데 실패했습니다.')
      }
    }

    loadAllCodes()
  }, [])

  useEffect(() => {
    const loadAllTerm = async (code: string) => {
      try {
        const terms = await termApi.getAllByCode(code)
        setTermList(terms)
      } catch (e) {
        message.error('약관을 불러오는데 실패했습니다.')
      }
    }

    if (selectedCode) {
      loadAllTerm(selectedCode)
    }
  }, [selectedCode])

  const handleSelectChange = (code: string) => {
    setSelectedCode(code)
  }

  const handleCreateBtnClick = () => {
    openCreateModal(codes)
  }

  const activatedTerm = termList
    .filter(terms => terms.deactivatedAt === null)
    .pop()

  const termHistories = termList
    .filter(terms => terms.deactivatedAt !== null)
    .sort((a, b) => {
      if (a.deactivatedAt && b.deactivatedAt) {
        return a.deactivatedAt > b.deactivatedAt ? -1 : 1
      }
      return 0
    })

  return (
    <>
      <Row>
        <div className={'text-2xl font-bold mb-3'}>약관 관리</div>
      </Row>
      <Row className={'mb-6'}>
        <Col span={24}>
          <Button
            type={'primary'}
            onClick={handleCreateBtnClick}
          >
            약관 추가
          </Button>
        </Col>
      </Row>
      <Row
        className={'mb-6'}
        gutter={20}
      >
        <Col span={12}>
          <TermsCodeSelector
            selectedCode={selectedCode}
            codes={codes}
            onChange={handleSelectChange}
          />
          {!activatedTerm && <Empty />}
          {activatedTerm && <UpdateTermsForm terms={activatedTerm} />}
        </Col>
        <Col span={12}>
          {termList.length > 0 && (
            <TermHistoryList termHistories={termHistories} />
          )}
        </Col>
      </Row>
    </>
  )
}

export default TermPage
