import { Layout } from 'antd'
import React from 'react'
import { Outlet } from 'react-router-dom'

import SideMenu from 'feature/ui/components/layout/MainSideMenu'

const { Content } = Layout

const MainLayout = () => {
  return (
    <Layout className="h-screen">
      <SideMenu />
      <Layout className="overflow-auto">
        <Content className={'m-3'}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  )
}

export default MainLayout
