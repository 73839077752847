import { Select } from 'antd'
import React from 'react'

const TermsCodeSelector = ({
  selectedCode,
  codes,
  onChange,
}: {
  selectedCode: string | null
  codes: string[]
  onChange: (code: string) => void
}) => {
  return (
    <Select
      className={'w-full mb-3'}
      placeholder={'약관 코드 선택'}
      value={selectedCode}
      onChange={onChange}
      options={codes.map(code => ({ value: code, label: code }))}
    />
  )
}

export default TermsCodeSelector
