import { adminAxios } from 'config/adminClientConfig'

import {
  CreateTermPayload,
  DeleteTermPayload,
  Terms,
  UpdateTermPayload,
} from 'type/client/terms'

const getAllCodes = () => {
  return adminAxios.get<string[]>(`/terms/codes`)
}

const create = (params: CreateTermPayload) => {
  return adminAxios.post<void>(`/terms`, params)
}

const getAllByCode = (code: string) => {
  return adminAxios.get<Terms[]>(`/terms/${code}/histories`)
}

const update = (id: number, params: UpdateTermPayload) => {
  return adminAxios.put<void>(`/terms/${id}`, params)
}

const deactivate = (id: number, params: DeleteTermPayload) => {
  return adminAxios.delete<void>(`/terms/${id}`, { data: params })
}

export const termApi = {
  getAllCodes,
  create,
  getAllByCode,
  update,
  deactivate,
}
