import { EditFilled } from '@ant-design/icons'
import { App, Modal } from 'antd'
import React from 'react'

import UpdateStoreRegistrationForm from 'feature/store-registration/componont/UpdateStoreRegistrationForm'

import { StoreRegistration } from 'type/client/storeRegistration'

const useUpdateStoreRegistrationFormModal = () => {
  const { modal } = App.useApp()

  const handleFormFinish = (onChangeStoreRegistration: () => void) => {
    return () => {
      Modal.destroyAll()
      onChangeStoreRegistration()
    }
  }

  const open = (
    storeRegistration: StoreRegistration,
    onChangeStore: () => void
  ) =>
    modal.confirm({
      title: '가게 등록 수정',
      icon: <EditFilled />,
      content: (
        <UpdateStoreRegistrationForm
          storeRegistration={storeRegistration}
          onFormFinish={handleFormFinish(onChangeStore)}
        />
      ),
      footer: null,
      closable: true,
      maskClosable: true,
      centered: true,
    })

  return {
    open,
  }
}

export default useUpdateStoreRegistrationFormModal
