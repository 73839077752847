import { UploadOutlined } from '@ant-design/icons'
import {
  Button,
  Form,
  Input,
  message,
  Select,
  Space,
  Upload,
  UploadFile,
} from 'antd'
import React from 'react'

import { useForm } from 'antd/lib/form/Form'

import { stickerApi } from 'feature/sticker/api'
import {
  defaultImageUploadFile,
  normalizeUploadFormItem,
} from 'feature/ui/util/upload'

import { Sticker, StickerCategory } from 'type/client/sticker'

interface UpdateStickerFormValueProps {
  name: string
  description: string
  imageFiles: UploadFile[]
  stickerCategory: StickerCategory
}

const UpdateStickerForm = ({
  stickerCategories,
  sticker,
  onFormFinish,
}: {
  stickerCategories: StickerCategory[]
  sticker: Sticker
  onFormFinish: () => void
}) => {
  const [form] = useForm()

  const initialFormValues: UpdateStickerFormValueProps = {
    name: sticker.name,
    description: sticker.description,
    imageFiles: [defaultImageUploadFile(sticker.imagePath, sticker.imageUrl)],
    stickerCategory: sticker.category,
  }

  const selectOptions = stickerCategories.map(category => ({
    label: category,
    value: category,
  }))

  const handleSubmitBtnClick = async ({
    name,
    description,
    imageFiles,
    stickerCategory,
  }: UpdateStickerFormValueProps) => {
    try {
      let imagePath: string
      if (imageFiles.length === 0 || sticker.imagePath === imageFiles[0].name) {
        imagePath = sticker.imagePath
      } else {
        imagePath = await uploadImage(imageFiles[0] as UploadFile)
      }

      await stickerApi.update(sticker.id, {
        name,
        description,
        stickerCategory,
        imagePath,
      })
      onFormFinish()
    } catch (e) {
      message.error('스티커 수정에 실패했습니다.')
    }
  }

  const uploadImage = async (imageFile: UploadFile) => {
    try {
      return await stickerApi.uploadImage(imageFile.originFileObj!)
    } catch (e) {
      message.error('이미지 업로드에 실패했습니다.')
      throw new Error('upload image failed')
    }
  }

  return (
    <Form
      form={form}
      layout={'vertical'}
      onFinish={handleSubmitBtnClick}
      initialValues={initialFormValues}
    >
      <Form.Item
        label="스티커명"
        name="name"
        rules={[
          {
            required: true,
            message: '스티커명을 입력해주세요',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="스티커 설명"
        name="description"
        rules={[
          {
            required: true,
            message: '스티커 설명을 입력해주세요',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="이미지"
        name="imageFiles"
        valuePropName="fileList"
        getValueFromEvent={normalizeUploadFormItem}
      >
        <Upload
          maxCount={1}
          beforeUpload={() => false}
          listType="picture"
        >
          <Button icon={<UploadOutlined />}>업로드</Button>
        </Upload>
      </Form.Item>
      <Form.Item
        label="스티커 카테고리"
        name="stickerCategory"
        rules={[
          {
            required: true,
            message: '스티커 카테고리를 선택해주세요',
          },
        ]}
      >
        <Select options={selectOptions} />
      </Form.Item>
      <Form.Item
        label="사유"
        name="reason"
        rules={[
          {
            required: true,
            message: '사유를 입력해주세요',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button
            className={'mt-3 w-[100px]'}
            type={'primary'}
            htmlType={'submit'}
          >
            수정
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default UpdateStickerForm
