import { EditFilled } from '@ant-design/icons'
import { App, Modal } from 'antd'
import React from 'react'

import DeactivateLoungeForm from 'feature/lounge/componont/DeactivateLoungeForm'

import { Lounge } from 'type/client/lounge'

const useDeactivateLoungeFormModal = () => {
  const { modal } = App.useApp()

  const handleFormFinish = (onChangeLounge: () => void) => {
    return () => {
      Modal.destroyAll()
      onChangeLounge()
    }
  }

  const open = (lounge: Lounge, onChangeLounge: () => void) =>
    modal.confirm({
      title: '라운지 비활성화',
      icon: <EditFilled />,
      content: (
        <DeactivateLoungeForm
          lounge={lounge}
          onFormFinish={handleFormFinish(onChangeLounge)}
        />
      ),
      footer: null,
      closable: true,
      maskClosable: true,
      centered: true,
    })

  return {
    open,
  }
}

export default useDeactivateLoungeFormModal
