import { EditFilled } from '@ant-design/icons'
import { App, Modal } from 'antd'
import React from 'react'

import UpdateStoreForm from 'feature/store/componont/UpdateStoreForm'

import { Store } from 'type/client/store'

const useUpdateStoreFormModal = () => {
  const { modal } = App.useApp()

  const handleFormFinish = (onChangeStore: () => void) => {
    return () => {
      Modal.destroyAll()
      onChangeStore()
    }
  }

  const open = (store: Store, onChangeStore: () => void) =>
    modal.confirm({
      title: '가게 수정',
      icon: <EditFilled />,
      content: (
        <UpdateStoreForm
          store={store}
          onFormFinish={handleFormFinish(onChangeStore)}
        />
      ),
      footer: null,
      closable: true,
      maskClosable: true,
      centered: true,
    })

  return {
    open,
  }
}

export default useUpdateStoreFormModal
