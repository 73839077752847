import { Button, Form, Input, message, Space } from 'antd'
import React from 'react'

import { useForm } from 'antd/lib/form/Form'
import TextArea from 'antd/lib/input/TextArea'

import { termApi } from 'feature/terms/api'
import useDeleteTermFormModal from 'feature/terms/hook/useDeleteTermFormModal'

import { Terms, UpdateTermPayload } from 'type/client/terms'

const UpdateTermsForm = ({ terms }: { terms: Terms }) => {
  const [form] = useForm()
  const { open: openDeleteModal } = useDeleteTermFormModal()

  const initialFormValues: UpdateTermPayload = {
    title: terms.title,
    content: terms.content,
    reason: '',
  }

  const handleDeleteBtnClick = () => {
    openDeleteModal(terms.id)
  }

  const handleSubmitBtnClick = async (values: UpdateTermPayload) => {
    try {
      await termApi.update(terms.id, values)
      message.success('약관이 수정되었습니다.')
    } catch (e) {
      message.error('약관 수정에 실패했습니다.')
    }
  }

  return (
    <Form
      form={form}
      layout={'vertical'}
      onFinish={handleSubmitBtnClick}
      initialValues={initialFormValues}
    >
      <Form.Item
        label="약관 제목"
        name="title"
        rules={[
          {
            required: true,
            message: '내용을 입력해주세요',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="약관 내용"
        name="content"
        rules={[
          {
            required: true,
            message: '내용을 입력해주세요',
          },
        ]}
      >
        <TextArea
          className="h-[50vh] resize-none"
          showCount
          maxLength={1000}
        />
      </Form.Item>
      <Form.Item
        label="수정 사유"
        name="reason"
        rules={[
          {
            required: true,
            message: '수정 사유를 입력해주세요',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button
            className={'mt-3 w-[100px]'}
            type={'primary'}
            htmlType={'submit'}
          >
            수정
          </Button>
          <Button
            className={'mt-3 w-[100px]'}
            onClick={handleDeleteBtnClick}
          >
            삭제
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default UpdateTermsForm
