import { Button, Form, Input, message, Space } from 'antd'
import React from 'react'

import { useForm } from 'antd/lib/form/Form'

import { termApi } from 'feature/terms/api'

import { DeleteTermPayload } from 'type/client/terms'

const DeleteTermsForm = ({
  termId,
  onFormFinish,
}: {
  termId: number
  onFormFinish: () => void
}) => {
  const [form] = useForm()

  const initialFormValues: DeleteTermPayload = {
    reason: '',
  }

  const handleSubmitBtnClick = async (values: DeleteTermPayload) => {
    try {
      await termApi.deactivate(termId, values)
      message.success('약관이 삭제되었습니다.')
      onFormFinish()
    } catch (e) {
      message.error('약관 삭제에 실패했습니다.')
    }
  }

  return (
    <Form
      form={form}
      layout={'vertical'}
      onFinish={handleSubmitBtnClick}
      initialValues={initialFormValues}
    >
      <Form.Item
        label="삭제 이유"
        name="reason"
        rules={[
          {
            required: true,
            message: '삭제 이유를 입력해주세요',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button
            className={'mt-3 w-[100px]'}
            type={'primary'}
            htmlType={'submit'}
          >
            삭제
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default DeleteTermsForm
