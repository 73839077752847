import { Table, TableProps, Tag } from 'antd'
import React from 'react'

import { ColumnsType } from 'antd/es/table'
import { TablePaginationConfig } from 'antd/es/table/interface'

import { addressToString } from 'feature/core/util/address'
import useDeactivateStoreFormModal from 'feature/store/hook/useDeactivateStoreFormModal'
import useUpdateStoreFormModal from 'feature/store/hook/useUpdateStoreFormModal'

import { Store } from 'type/client/store'
import { Page } from 'type/client/type'

const StoreTable = ({
  isLoading,
  stores,
  loadStores,
}: {
  isLoading: boolean
  stores: Page<Store>
  loadStores: (page: number) => void
}) => {
  const { open: openUpdateModal } = useUpdateStoreFormModal()
  const { open: openDeactivateModal } = useDeactivateStoreFormModal()

  const handleDeactivateATagClick = (store: Store) => {
    return () => {
      openDeactivateModal(store, reloadCurrentPage)
    }
  }

  const handleEditATagClick = (store: Store) => {
    return () => {
      openUpdateModal(store, reloadCurrentPage)
    }
  }

  const handleTableChange: TableProps['onChange'] = (
    paginationConfig,
    _filters,
    _sorter
  ) => {
    const page = (paginationConfig.current || 1) - 1
    loadStores(page)
  }

  const reloadCurrentPage = () => {
    loadStores(stores.number)
  }

  const tablePagination: TablePaginationConfig = {
    current: stores.number + 1,
    pageSize: stores.size,
    total: stores.totalElements,
  }

  const columns: ColumnsType<Store> = [
    {
      title: '상태',
      key: 'status',
      render: (_, record) => {
        const isActivate = !record.deactivatedAt
        const status = isActivate ? '활성화' : '비활성화'

        return <Tag color={isActivate ? 'green' : 'red'}>{status}</Tag>
      },
    },
    {
      title: '가게명',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '전화번호',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: '주소',
      dataIndex: 'address',
      key: 'address',
      render: (_, record) => addressToString(record.address),
    },
    {
      title: '로고 이미지',
      dataIndex: 'logoImageUrl',
      key: 'logoImageUrl',
      render: (_, record) =>
        !record.logoImageUrl ? null : (
          <img
            src={record.logoImageUrl}
            alt={record.name}
          />
        ),
    },
    {
      title: '생성일',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: '비활성화일',
      dataIndex: 'deactivatedAt',
      key: 'deactivatedAt',
    },
    {
      title: '',
      width: 300,
      key: 'action',
      render: (_, record) => (
        <div className={'flex gap-3'}>
          <a onClick={handleEditATagClick(record)}>수정</a>
          <a onClick={handleDeactivateATagClick(record)}>비활성화</a>
        </div>
      ),
    },
  ]

  return (
    <Table
      onChange={handleTableChange}
      loading={isLoading}
      rowKey={'id'}
      dataSource={stores.content}
      columns={columns}
      bordered={true}
      scroll={{ y: 650 }}
      pagination={tablePagination}
    />
  )
}

export default StoreTable
