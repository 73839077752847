import { RcFile } from 'antd/lib/upload'

import { adminAxios } from 'config/adminClientConfig'

import { S3ResourceType } from 'type/client/upload'

const uploadTempFile = async (resourceType: S3ResourceType, file: RcFile) => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('resourceType', resourceType)

  return adminAxios.post<string>(`/upload/temp`, formData)
}

export const uploadApi = {
  uploadTempFile,
}
