import { EditFilled } from '@ant-design/icons'
import { App, Modal } from 'antd'
import React from 'react'

import CreateStickerForm from 'feature/sticker/componont/CreateStickerForm'

import { StickerCategory } from 'type/client/sticker'

const useCreateStickerFormModal = () => {
  const { modal } = App.useApp()

  const handleFormFinish = (onChangeSticker: () => void) => {
    return () => {
      Modal.destroyAll()
      onChangeSticker()
    }
  }

  const open = (
    stickerCategories: StickerCategory[],
    onChangeSticker: () => void
  ) =>
    modal.confirm({
      title: '스티커 추가',
      icon: <EditFilled />,
      content: (
        <CreateStickerForm
          stickerCategories={stickerCategories}
          onFormFinish={handleFormFinish(onChangeSticker)}
        />
      ),
      footer: null,
      closable: true,
      maskClosable: true,
      centered: true,
    })

  return {
    open,
  }
}

export default useCreateStickerFormModal
