import { Address } from 'type/client/type'

export const addressToString = (address: Address) =>
  address.sido +
  ' ' +
  address.sigungu +
  ' ' +
  address.roadAddress.roadName +
  ' ' +
  address.roadAddress.roadNumber +
  ' ' +
  (address.detail ?? '')
