import { Table, Tag } from 'antd'
import React from 'react'

import { ColumnsType } from 'antd/es/table'

import useDeactivateStickerFormModal from 'feature/sticker/hook/useDeactivateStickerFormModal'
import useUpdateStickerFormModal from 'feature/sticker/hook/useUpdateStickerFormModal'

import { Sticker, StickerCategory } from 'type/client/sticker'

const StickerTable = ({
  stickerCategories,
  isLoading,
  stickerCategory,
  stickers,
  loadStickers,
}: {
  stickerCategories: StickerCategory[]
  isLoading: boolean
  stickerCategory: StickerCategory
  stickers: Sticker[]
  loadStickers: () => void
}) => {
  const { open: openUpdateModal } = useUpdateStickerFormModal()
  const { open: openDeactivateModal } = useDeactivateStickerFormModal()

  const handleDeactivateATagClick = (sticker: Sticker) => {
    return () => {
      openDeactivateModal(sticker, loadStickers)
    }
  }

  const handleEditATagClick = (sticker: Sticker) => {
    return () => {
      openUpdateModal(stickerCategories, sticker, loadStickers)
    }
  }

  const columns: ColumnsType<Sticker> = [
    {
      title: '상태',
      key: 'status',
      render: (_, record) => {
        const isActivate = !record.deactivatedAt
        const status = isActivate ? '활성화' : '비활성화'

        return <Tag color={isActivate ? 'green' : 'red'}>{status}</Tag>
      },
    },
    {
      title: '카테고리',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'UUID',
      dataIndex: 'uuid',
      key: 'uuid',
    },
    {
      title: '설명',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: '이미지',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      render: (_, record) => (
        <img
          src={record.imageUrl}
          alt={record.name}
          style={{ width: 30 }}
        />
      ),
    },
    {
      title: 's3 경로',
      dataIndex: 'imagePath',
      key: 'imagePath',
    },
    {
      title: '생성일',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: '비활성화일',
      dataIndex: 'deactivatedAt',
      key: 'deactivatedAt',
    },
    {
      title: '',
      width: 300,
      key: 'action',
      render: (_, record) => (
        <div className={'flex gap-3'}>
          <a onClick={handleEditATagClick(record)}>수정</a>
          <a onClick={handleDeactivateATagClick(record)}>비활성화</a>
        </div>
      ),
    },
  ]

  return (
    <>
      <h2>{stickerCategory}</h2>
      <Table
        loading={isLoading}
        rowKey={'id'}
        dataSource={stickers}
        columns={columns}
        bordered={true}
        pagination={false}
      />
    </>
  )
}

export default StickerTable
