import { Row } from 'antd'
import React from 'react'

const HomePage = () => {
  return (
    <Row>
      <h1>Home</h1>
    </Row>
  )
}

export default HomePage
