import { Table, TableProps, Tag } from 'antd'
import React from 'react'

import { ColumnsType } from 'antd/es/table'
import { TablePaginationConfig } from 'antd/es/table/interface'

import useDeactivateNoticeFormModal from 'feature/notice/hook/useDeactivateNoticeFormModal'
import useEditNoticeFormModal from 'feature/notice/hook/useEditNoticeFormModal'

import { Notice } from 'type/client/notice'
import { Page } from 'type/client/type'

import type { GetComponentProps } from 'rc-table/lib/interface'

const NoticeTable = ({
  isLoading,
  notices,
  loadNotices,
  selectNotice,
}: {
  isLoading: boolean
  notices: Page<Notice>
  loadNotices: (page: number) => void
  selectNotice: (notice: Notice) => void
}) => {
  const { open: openEditModal } = useEditNoticeFormModal()
  const { open: openDeactivateModal } = useDeactivateNoticeFormModal()

  const handleDeactivateATagClick = (notice: Notice) => {
    return () => {
      openDeactivateModal(notice, reloadCurrentPage)
    }
  }

  const handleEditATagClick = (notice: Notice) => {
    return () => {
      openEditModal(notice, reloadCurrentPage)
    }
  }

  const handleTableChange: TableProps['onChange'] = (
    paginationConfig,
    _filters,
    _sorter
  ) => {
    const page = (paginationConfig.current || 1) - 1
    loadNotices(page)
  }

  const handleTableRow: GetComponentProps<Notice> = (record, _index) => {
    return {
      onClick: _event => {
        selectNotice(record)
      },
    }
  }

  const reloadCurrentPage = () => {
    loadNotices(notices.number)
  }

  const columns: ColumnsType<Notice> = [
    {
      title: '상태',
      key: 'status',
      width: 80,
      render: (_, record) => {
        const isActivate = !record.deactivatedAt
        const status = isActivate ? '활성화' : '비활성화'

        return <Tag color={isActivate ? 'green' : 'red'}>{status}</Tag>
      },
    },
    {
      title: '타입',
      dataIndex: 'type',
      key: 'type',
      width: 80,
      render: (_, record) => {
        switch (record.type) {
          case 'NOTICE':
            return '공지'
          case 'EVENT':
            return '이벤트'
          default:
            return '알 수 없음'
        }
      },
    },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '생성일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 160,
    },
    {
      title: '비활성화일',
      dataIndex: 'deactivatedAt',
      key: 'deactivatedAt',
      width: 160,
    },
    {
      title: '',
      width: 130,
      key: 'action',
      render: (_, record) => (
        <div className={'flex gap-3'}>
          <a onClick={handleEditATagClick(record)}>수정</a>
          <a onClick={handleDeactivateATagClick(record)}>비활성화</a>
        </div>
      ),
    },
  ]

  const tablePagination: TablePaginationConfig = {
    current: notices.number + 1,
    pageSize: notices.size,
    total: notices.totalElements,
  }

  return (
    <Table
      onRow={handleTableRow}
      loading={isLoading}
      onChange={handleTableChange}
      rowKey={'id'}
      dataSource={notices.content}
      columns={columns}
      bordered={true}
      scroll={{ y: 580 }}
      pagination={tablePagination}
    />
  )
}

export default NoticeTable
