import { Button, message, Table, TableProps } from 'antd'
import React from 'react'

import { ColumnsType } from 'antd/es/table'
import {
  TablePaginationConfig,
  TableRowSelection,
} from 'antd/es/table/interface'

import { addressToString } from 'feature/core/util/address'
import { storeRegistrationApi } from 'feature/store-registration/api'
import useRejectStoreRegistrationFormModal from 'feature/store-registration/hook/useRejectStoreRegistrationFormModal'
import useUpdateStoreRegistrationFormModal from 'feature/store-registration/hook/useUpdateStoreRegistrationFormModal'

import {
  RegisterStoreRegistrationPayload,
  StoreRegistration,
} from 'type/client/storeRegistration'
import { Page } from 'type/client/type'

const StoreRegistrationTable = ({
  isLoading,
  storeRegistrations,
  loadStoreRegistrations,
}: {
  isLoading: boolean
  storeRegistrations: Page<StoreRegistration>
  loadStoreRegistrations: (page: number) => void
}) => {
  const [selectedRows, setSelectedRows] = React.useState<StoreRegistration[]>(
    []
  )

  const { open: openUpdateModal } = useUpdateStoreRegistrationFormModal()
  const { open: openRejectModal } = useRejectStoreRegistrationFormModal()

  const handleRejectATagClick = (storeRegistration: StoreRegistration) => {
    return () => {
      openRejectModal([storeRegistration], reloadCurrentPage)
    }
  }

  const handleEditATagClick = (storeRegistration: StoreRegistration) => {
    return () => {
      openUpdateModal(storeRegistration, reloadCurrentPage)
    }
  }

  const registerStoreRegistrations = async (
    value: RegisterStoreRegistrationPayload
  ) => {
    try {
      await storeRegistrationApi.register(value)
      reloadCurrentPage()
    } catch (e) {
      message.error('가게 등록에 실패했습니다.')
    }
  }

  const handleRegisterATagClick = (storeRegistration: StoreRegistration) => {
    return async () => {
      await registerStoreRegistrations({ ids: [storeRegistration.id] })
    }
  }

  const handleTableChange: TableProps['onChange'] = (
    paginationConfig,
    _filters,
    _sorter
  ) => {
    const page = (paginationConfig.current || 1) - 1
    setSelectedRows([])
    loadStoreRegistrations(page)
  }

  const handleAllRegisterBtnClick = async () => {
    const payload = { ids: selectedRows.map(value => value.id) }
    await registerStoreRegistrations(payload)
  }

  const handleAllRejectBtnClick = async () => {
    openRejectModal(selectedRows, reloadCurrentPage)
  }

  const tableRowSelection: TableRowSelection<StoreRegistration> = {
    selectedRowKeys: selectedRows.map(value => value.id),
    onSelect: (record, selected) => {
      if (selected) {
        setSelectedRows([...selectedRows, record])
      } else {
        setSelectedRows(prevState =>
          prevState.filter(selectedRow => selectedRow.id !== record.id)
        )
      }
    },
    onChange: (_selectedRowKeys, selectedRows, _info) => {
      setSelectedRows(selectedRows)
    },
  }

  const tablePagination: TablePaginationConfig = {
    current: storeRegistrations.number + 1,
    pageSize: storeRegistrations.size,
    total: storeRegistrations.totalElements,
  }

  const reloadCurrentPage = () => {
    loadStoreRegistrations(storeRegistrations.number)
  }

  const columns: ColumnsType<StoreRegistration> = [
    {
      title: '가게명',
      dataIndex: 'storeName',
      key: 'storeName',
    },
    {
      title: '주소',
      dataIndex: 'address',
      key: 'address',
      render: (_, record) => addressToString(record.address),
    },
    {
      title: '직접 입력 주소',
      dataIndex: 'manualAddress',
      key: 'manualAddress',
      render: (_, record) => record.manualAddress,
    },
    {
      title: '생성일',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: '',
      width: 300,
      key: 'action',
      render: (_, record) => (
        <div className={'flex gap-3'}>
          <a onClick={handleEditATagClick(record)}>수정</a>
          <a onClick={handleRegisterATagClick(record)}>등록</a>
          <a onClick={handleRejectATagClick(record)}>거절</a>
        </div>
      ),
    },
  ]

  return (
    <div className={'flex flex-col gap-3'}>
      <div className={'flex gap-3'}>
        <Button
          type={'primary'}
          onClick={handleAllRegisterBtnClick}
        >
          선택 가게 등록
        </Button>
        <Button onClick={handleAllRejectBtnClick}>선택 가게 거절</Button>
      </div>
      <Table
        rowSelection={tableRowSelection}
        onChange={handleTableChange}
        loading={isLoading}
        rowKey={'id'}
        dataSource={storeRegistrations.content}
        columns={columns}
        bordered={true}
        scroll={{ y: 650 }}
        pagination={tablePagination}
      />
    </div>
  )
}

export default StoreRegistrationTable
