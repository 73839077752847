import { adminAxios } from 'config/adminClientConfig'

import {
  RegisterStoreRegistrationPayload,
  RejectStoreRegistrationPayload,
  StoreRegistration,
  UpdateStoreRegistrationPayload,
} from 'type/client/storeRegistration'
import { Page } from 'type/client/type'

const getAll = async (page: number, keyword: string) => {
  return adminAxios.get<Page<StoreRegistration>>(
    `/store-registrations?page=${page}&keyword=${keyword}`
  )
}

const update = async (id: number, params: UpdateStoreRegistrationPayload) => {
  return adminAxios.put<void>(`/store-registrations/${id}`, params)
}

const register = async (params: RegisterStoreRegistrationPayload) => {
  return adminAxios.post<void>('/store-registrations/register', params)
}

const reject = async (params: RejectStoreRegistrationPayload) => {
  return adminAxios.post<void>(`/store-registrations/reject`, params)
}

export const storeRegistrationApi = {
  getAll,
  register,
  reject,
  update,
}
