export interface Address {
  sido: string
  sigungu: string
  roadAddress: RoadAddress
  lotAddress: LotAddress
  detail: string | null
  zipCode: number
  location: Location
}

export interface RoadAddress {
  roadName: string
  roadNumber: string
}

export interface LotAddress {
  lotName: string
  lotNumber: string
}

export interface Location {
  latitude: number
  longitude: number
}

export interface Page<T> {
  content: T[]
  last: boolean
  number: number
  size: number
  totalPages: number
  totalElements: number
}

export const emptyPage = <T>() =>
  ({
    content: [],
    last: false,
    number: 0,
    size: 0,
    totalPages: 0,
    totalElements: 0,
  }) as Page<T>
