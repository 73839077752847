import { Col, Input, message, Row } from 'antd'
import React, { useEffect, useState } from 'react'

import { storeRegistrationApi } from 'feature/store-registration/api'
import StoreRegistrationTable from 'feature/store-registration/componont/StoreRegistrationTable'

import { StoreRegistration } from 'type/client/storeRegistration'
import { emptyPage, Page } from 'type/client/type'

const StoreRegistrationPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [query, setQuery] = useState<string>('')
  const [storeRegistrations, setStoreRegistrations] =
    useState<Page<StoreRegistration>>(emptyPage)

  useEffect(() => {
    loadFirstPage()
  }, [])

  const loadStoreRegistrations = async (page: number) => {
    setIsLoading(true)
    try {
      const response = await storeRegistrationApi.getAll(page, query)
      setStoreRegistrations(response)
    } catch (error) {
      message.error('가게 등록 요청 목록을 불러오는데 실패했습니다.')
    } finally {
      setIsLoading(false)
    }
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
  }

  const handleSearch = () => loadFirstPage()

  const loadFirstPage = () => loadStoreRegistrations(0)

  return (
    <>
      <Row>
        <div className={'text-2xl font-bold mb-3'}>가게 등록 요청 관리</div>
      </Row>
      <Row className={'mb-6'}>
        <Col span={8}>
          <Input.Search
            onSearch={handleSearch}
            onChange={handleSearchChange}
            placeholder={'가게명을 검색하세요'}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <StoreRegistrationTable
            isLoading={isLoading}
            storeRegistrations={storeRegistrations}
            loadStoreRegistrations={loadStoreRegistrations}
          />
        </Col>
      </Row>
    </>
  )
}

export default StoreRegistrationPage
