import { RcFile } from 'antd/lib/upload'

import { adminAxios } from 'config/adminClientConfig'

import {
  CreateStickerPayload,
  DeactivateStickerPayload,
  Sticker,
  StickerCategory,
  UpdateStickerPayload,
} from 'type/client/sticker'

const uploadImage = async (file: RcFile) => {
  const formData = new FormData()
  formData.append('file', file)

  return adminAxios.post<string>(`/stickers/upload-image`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  })
}

const getAllCategories = async () => {
  return adminAxios.get<StickerCategory[]>(`/stickers/categories`)
}

const getAll = async () => {
  return adminAxios.get<Sticker[]>(`/stickers`)
}

const create = async (params: CreateStickerPayload) => {
  return adminAxios.post<void>('/stickers', params)
}

const update = async (id: number, params: UpdateStickerPayload) => {
  return adminAxios.put<void>(`/stickers/${id}`, params)
}

const deactivate = async (id: number, params: DeactivateStickerPayload) => {
  return adminAxios.delete<void>(`/stickers/${id}`, {
    data: params,
  })
}

export const stickerApi = {
  getAll,
  getAllCategories,
  uploadImage,
  create,
  update,
  deactivate,
}
