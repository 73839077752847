import { EditFilled } from '@ant-design/icons'
import { App, Modal } from 'antd'
import React from 'react'

import DeactivateStickerForm from 'feature/sticker/componont/DeactivateStickerForm'

import { Sticker } from 'type/client/sticker'

const useDeactivateStickerFormModal = () => {
  const { modal } = App.useApp()

  const handleFormFinish = (onChangeSticker: () => void) => {
    return () => {
      Modal.destroyAll()
      onChangeSticker()
    }
  }

  const open = (sticker: Sticker, onChangeSticker: () => void) =>
    modal.confirm({
      title: '스티커 비활성화',
      icon: <EditFilled />,
      content: (
        <DeactivateStickerForm
          sticker={sticker}
          onFormFinish={handleFormFinish(onChangeSticker)}
        />
      ),
      footer: null,
      closable: true,
      maskClosable: true,
      centered: true,
    })

  return {
    open,
  }
}

export default useDeactivateStickerFormModal
