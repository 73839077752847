import { Button, Form, Input, message, Space } from 'antd'
import React from 'react'

import { useForm } from 'antd/lib/form/Form'

import { noticeApi } from 'feature/notice/api'

import { DeactivateNoticePayload, Notice } from 'type/client/notice'

const DeactivateNoticeForm = ({
  notice,
  onFormFinish,
}: {
  notice: Notice
  onFormFinish: () => void
}) => {
  const [form] = useForm()

  const initialFormValues = {
    reason: '',
  }

  const handleSubmitBtnClick = async (values: DeactivateNoticePayload) => {
    try {
      await noticeApi.deactivate(notice.id, values)
      onFormFinish()
    } catch (e) {
      message.error('공지사항 비활성화에 실패했습니다.')
    }
  }

  return (
    <Form
      form={form}
      layout={'vertical'}
      onFinish={handleSubmitBtnClick}
      initialValues={initialFormValues}
    >
      <Form.Item
        label="사유"
        name="reason"
        rules={[
          {
            required: true,
            message: '사유를 입력해주세요',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button
            className={'mt-3 w-[100px]'}
            type={'primary'}
            htmlType={'submit'}
          >
            비활성화
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default DeactivateNoticeForm
