import { Button, Form, Input, message, Space } from 'antd'
import React from 'react'

import { useForm } from 'antd/lib/form/Form'

import { loungeApi } from 'feature/lounge/api'

import { Lounge, UpdateLoungePayload } from 'type/client/lounge'

const UpdateLoungeForm = ({
  lounge,
  onFormFinish,
}: {
  lounge: Lounge
  onFormFinish: () => void
}) => {
  const [form] = useForm()

  const initialFormValues: UpdateLoungePayload = {
    name: lounge.name,
    uuid: lounge.uuid,
    reason: '',
  }

  const handleSubmitBtnClick = async (values: UpdateLoungePayload) => {
    try {
      await loungeApi.update(lounge.id, values)
      onFormFinish()
    } catch (e) {
      message.error('라운지 수정에 실패했습니다.')
    }
  }

  return (
    <Form
      form={form}
      layout={'vertical'}
      onFinish={handleSubmitBtnClick}
      initialValues={initialFormValues}
    >
      <Form.Item
        label="라운지명"
        name="name"
        rules={[
          {
            required: true,
            message: '라운지명을 입력해주세요',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="라운지 UUID"
        name="uuid"
        rules={[
          {
            required: true,
            message: '라운지 UUID를 입력해주세요',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="사유"
        name="reason"
        rules={[
          {
            required: true,
            message: '사유를 입력해주세요',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button
            className={'mt-3 w-[100px]'}
            type={'primary'}
            htmlType={'submit'}
          >
            수정
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default UpdateLoungeForm
