import { Button, Card, Col, Empty, message, Row } from 'antd'
import React, { useEffect, useState } from 'react'

import { noticeApi } from 'feature/notice/api'
import NoticeTable from 'feature/notice/componont/NoticeTable'
import useWriteNoticeFormModal from 'feature/notice/hook/useWriteNoticeFormModal'

import { Notice } from 'type/client/notice'
import { emptyPage, Page } from 'type/client/type'

const NoticePage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [notices, setNotices] = useState<Page<Notice>>(emptyPage)
  const [selectedNotice, setSelectedNotice] = useState<Notice | null>(null)

  const { open: openCreateModal } = useWriteNoticeFormModal()

  useEffect(() => {
    loadFirstPage()
  }, [])

  const loadNotices = async (page: number) => {
    setIsLoading(true)
    try {
      const response = await noticeApi.getAll(page)
      setNotices(response)

      if (selectedNotice) {
        const selectedNoticeId = selectedNotice.id
        const newSelectedNotice = response.content.find(
          notice => notice.id === selectedNoticeId
        )
        setSelectedNotice(newSelectedNotice || null)
      }
    } catch (error) {
      message.error('공지사항 목록을 불러오는데 실패했습니다.')
    } finally {
      setIsLoading(false)
    }
  }

  const loadFirstPage = () => loadNotices(0)

  const handleCreateBtnClick = () => {
    openCreateModal(loadFirstPage)
  }

  const selectNotice = (notice: Notice) => {
    setSelectedNotice(notice)
  }

  return (
    <>
      <Row>
        <div className={'text-2xl font-bold mb-3'}>공지사항 관리</div>
      </Row>
      <Row className={'mb-6'}>
        <Col span={24}>
          <Button
            type={'primary'}
            onClick={handleCreateBtnClick}
          >
            공지사항 작성
          </Button>
        </Col>
      </Row>
      <Row gutter={6}>
        <Col span={14}>
          <NoticeTable
            isLoading={isLoading}
            notices={notices}
            loadNotices={loadNotices}
            selectNotice={selectNotice}
          />
        </Col>
        <Col span={10}>
          {selectedNotice ? (
            <Card
              title={selectedNotice.title}
              extra={selectedNotice.createdAt}
            >
              <div
                dangerouslySetInnerHTML={{ __html: selectedNotice.content }}
              />
            </Card>
          ) : (
            <Card>
              <Empty description={'공지사항을 선택해주세요'} />
            </Card>
          )}
        </Col>
      </Row>
    </>
  )
}

export default NoticePage
