import { adminAxios } from 'config/adminClientConfig'

import {
  DeactivateNoticePayload,
  EditNoticePayload,
  Notice,
  WriteNoticePayload,
} from 'type/client/notice'
import { Page } from 'type/client/type'

const getAll = async (page: number) => {
  return adminAxios.get<Page<Notice>>(`/notices?page=${page}`)
}

const create = async (params: WriteNoticePayload) => {
  return adminAxios.post<void>('/notices', params)
}

const update = async (id: number, params: EditNoticePayload) => {
  return adminAxios.put<void>(`/notices/${id}`, params)
}

const deactivate = async (id: number, params: DeactivateNoticePayload) => {
  return adminAxios.delete<void>(`/notices/${id}`, {
    data: params,
  })
}

export const noticeApi = {
  getAll,
  create,
  update,
  deactivate,
}
