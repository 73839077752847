import { Button, Form, Input, message, Select, Space } from 'antd'
import React from 'react'

import { useForm } from 'antd/lib/form/Form'
import TextArea from 'antd/lib/input/TextArea'

import { termApi } from 'feature/terms/api'

import { CreateTermPayload } from 'type/client/terms'

const CreateTermsForm = ({
  termsCodes,
  onFormFinish,
}: {
  termsCodes: string[]
  onFormFinish: (code: string) => void
}) => {
  const [form] = useForm()

  const initialFormValues: CreateTermPayload = {
    code: '',
    title: '',
    content: '',
  }

  const termsCodeOptions = termsCodes.map(code => ({
    label: code,
    value: code,
  }))

  const handleSubmitBtnClick = async (values: CreateTermPayload) => {
    try {
      await termApi.create(values)
      message.success('약관이 생성되었습니다.')
      onFormFinish(values.code)
    } catch (e) {
      message.error('약관 생성에 실패했습니다.')
    }
  }

  return (
    <Form
      form={form}
      layout={'vertical'}
      onFinish={handleSubmitBtnClick}
      initialValues={initialFormValues}
    >
      <Form.Item
        label="약관 코드"
        name="code"
        rules={[
          {
            required: true,
            message: '코드를 입력해주세요',
          },
        ]}
      >
        <Select options={termsCodeOptions} />
      </Form.Item>
      <Form.Item
        label="약관 제목"
        name="title"
        rules={[
          {
            required: true,
            message: '내용을 입력해주세요',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="약관 내용"
        name="content"
        rules={[
          {
            required: true,
            message: '내용을 입력해주세요',
          },
        ]}
      >
        <TextArea
          className="h-[50vh] resize-none"
          showCount
          maxLength={1000}
        />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button
            className={'mt-3 w-[100px]'}
            type={'primary'}
            htmlType={'submit'}
          >
            추가
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default CreateTermsForm
