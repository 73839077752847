import { adminAxios } from 'config/adminClientConfig'

import {
  CreateLoungePayload,
  DeactivateLoungePayload,
  Lounge,
  UpdateLoungePayload,
} from 'type/client/lounge'

const getAll = async () => {
  return adminAxios.get<Lounge[]>(`/lounges`)
}

const create = async (params: CreateLoungePayload) => {
  return adminAxios.post<void>('/lounges', params)
}

const update = async (id: number, params: UpdateLoungePayload) => {
  return adminAxios.put<void>(`/lounges/${id}`, params)
}

const deactivate = async (id: number, params: DeactivateLoungePayload) => {
  return adminAxios.delete<void>(`/lounges/${id}`, {
    data: params,
  })
}

export const loungeApi = {
  getAll,
  create,
  update,
  deactivate,
}
