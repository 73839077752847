import { Button, Form, Input, message, Space } from 'antd'
import React from 'react'

import { useForm } from 'antd/lib/form/Form'

import { loungeApi } from 'feature/lounge/api'

import { DeactivateLoungePayload, Lounge } from 'type/client/lounge'

const DeactivateLoungeForm = ({
  lounge,
  onFormFinish,
}: {
  lounge: Lounge
  onFormFinish: () => void
}) => {
  const [form] = useForm()

  const initialFormValues = {
    reason: '',
  }

  const handleSubmitBtnClick = async (values: DeactivateLoungePayload) => {
    try {
      await loungeApi.deactivate(lounge.id, values)
      onFormFinish()
    } catch (e) {
      message.error('라운지 비활성화에 실패했습니다.')
    }
  }

  return (
    <Form
      form={form}
      layout={'vertical'}
      onFinish={handleSubmitBtnClick}
      initialValues={initialFormValues}
    >
      <Form.Item
        label="사유"
        name="reason"
        rules={[
          {
            required: true,
            message: '사유를 입력해주세요',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button
            className={'mt-3 w-[100px]'}
            type={'primary'}
            htmlType={'submit'}
          >
            비활성화
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default DeactivateLoungeForm
