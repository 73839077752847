import { Card, List } from 'antd'
import React from 'react'

import { Terms } from 'type/client/terms'

const TermHistoryList = ({ termHistories }: { termHistories: Terms[] }) => (
  <div>
    <div className={'text-xl font-bold'}>수정 내역</div>
    <List
      className="overflow-auto h-[80vh]"
      itemLayout="vertical"
      dataSource={termHistories}
      renderItem={item => (
        <List.Item>
          <Card
            title={
              <div
                key={item.id}
                className={'flex justify-between'}
              >
                <div>생성일 : {item.createdAt}</div>
                <div>삭제일 : {item.deactivatedAt}</div>
              </div>
            }
          >
            <div className={'text-xl font-bold mb-3'}>{item.title}</div>
            <div className={'text-l font-bold '}>
              수정 사유 : {item.deactivatedReason}
            </div>
            <p>{item.content}</p>
          </Card>
        </List.Item>
      )}
    />
  </div>
)

export default TermHistoryList
