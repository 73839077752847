import { App as AntdApp } from 'antd'
import React from 'react'

import MainRoutes from 'route/Routes'

function App() {
  return (
    <AntdApp>
      <MainRoutes />
    </AntdApp>
  )
}

export default App
