import { Button, Col, message, Row } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'

import { stickerApi } from 'feature/sticker/api'
import StickerTable from 'feature/sticker/componont/StickerTable'
import useCreateStickerFormModal from 'feature/sticker/hook/useCreateStickerFormModal'

import { Sticker, StickerCategory } from 'type/client/sticker'

const StickerPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [stickers, setStickers] = useState<Sticker[]>([])
  const [stickerCategories, setStickerCategories] = useState<StickerCategory[]>(
    []
  )

  const { open: openCreateModal } = useCreateStickerFormModal()

  useEffect(() => {
    loadStickers()
  }, [])

  const loadStickers = async () => {
    setIsLoading(true)

    try {
      const stickerCategories = await stickerApi.getAllCategories()
      setStickerCategories(stickerCategories)

      const stickers = await stickerApi.getAll()
      setStickers(stickers)
    } catch (error) {
      message.error('스티커 목록을 불러오는데 실패했습니다.')
    } finally {
      setIsLoading(false)
    }
  }

  const stickersByCategory = useMemo(
    () =>
      stickerCategories?.map(category => ({
        category,
        stickers: stickers.filter(sticker => sticker.category === category),
      })),
    [stickerCategories, stickers]
  )

  const handleCreateBtnClick = () => {
    openCreateModal(stickerCategories, loadStickers)
  }

  return (
    <>
      <Row>
        <div className={'text-2xl font-bold mb-3'}>스티커 관리</div>
      </Row>
      <Row className={'mb-6'}>
        <Col span={24}>
          <Button
            type={'primary'}
            onClick={handleCreateBtnClick}
          >
            스티커 추가
          </Button>
        </Col>
      </Row>
      {stickersByCategory?.map(({ category, stickers }) => (
        <Row key={category}>
          <Col span={24}>
            <StickerTable
              stickerCategories={stickerCategories}
              isLoading={isLoading}
              stickerCategory={category}
              stickers={stickers}
              loadStickers={loadStickers}
            />
          </Col>
        </Row>
      ))}
    </>
  )
}

export default StickerPage
