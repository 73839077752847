import { EditFilled } from '@ant-design/icons'
import { App, Modal } from 'antd'
import React from 'react'

import DeleteTermsForm from 'feature/terms/component/DeleteTermsForm'

const useDeleteTermFormModal = () => {
  const { modal } = App.useApp()

  const handleFormFinish = () => {
    Modal.destroyAll()
  }
  const open = (termId: number) =>
    modal.confirm({
      title: '약관 삭제',
      icon: <EditFilled />,
      content: (
        <DeleteTermsForm
          termId={termId}
          onFormFinish={handleFormFinish}
        />
      ),
      footer: null,
      closable: true,
      maskClosable: true,
      centered: true,
    })

  return {
    open,
  }
}

export default useDeleteTermFormModal
