import { EditFilled } from '@ant-design/icons'
import { App, Modal } from 'antd'
import React from 'react'

import CreateTermsForm from 'feature/terms/component/CreateTermsForm'

const useCreateTermFormModal = (onFinish: (code: string) => void) => {
  const { modal } = App.useApp()

  const handleFormFinish = (code: string) => {
    Modal.destroyAll()
    onFinish(code)
  }
  const open = (termsCodes: string[]) =>
    modal.confirm({
      title: '약관 추가',
      icon: <EditFilled />,
      content: (
        <CreateTermsForm
          termsCodes={termsCodes}
          onFormFinish={handleFormFinish}
        />
      ),
      footer: null,
      closable: true,
      maskClosable: true,
      centered: true,
    })

  return {
    open,
  }
}

export default useCreateTermFormModal
