import { Button, Form, Input, message, Select, Space } from 'antd'
import React from 'react'

import { useForm } from 'antd/lib/form/Form'

import { noticeApi } from 'feature/notice/api'

import { EditNoticePayload, Notice, NoticeType } from 'type/client/notice'

const EditNoticeForm = ({
  notice,
  onFormFinish,
}: {
  notice: Notice
  onFormFinish: () => void
}) => {
  const [form] = useForm()

  const initialFormValues: EditNoticePayload = {
    type: notice.type,
    title: notice.title,
    content: notice.content,
  }

  const handleSubmitBtnClick = async (values: EditNoticePayload) => {
    try {
      await noticeApi.update(notice.id, values)
      onFormFinish()
    } catch (e) {
      message.error('공지사항 수정에 실패했습니다.')
    }
  }

  return (
    <Form
      form={form}
      layout={'vertical'}
      onFinish={handleSubmitBtnClick}
      initialValues={initialFormValues}
    >
      <Form.Item
        label="타입"
        name="type"
        rules={[
          {
            required: true,
            message: '타입을 선택해주세요',
          },
        ]}
      >
        <Select>
          <Select.Option value={NoticeType.NOTICE}>공지</Select.Option>
          <Select.Option value={NoticeType.EVENT}>이벤트</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="제목"
        name="title"
        rules={[
          {
            required: true,
            message: '제목을 입력해주세요',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="내용"
        name="content"
        rules={[
          {
            required: true,
            message: '내용을 입력해주세요',
          },
        ]}
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        label="사유"
        name="reason"
        rules={[
          {
            required: true,
            message: '사유를 입력해주세요',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button
            className={'mt-3 w-[100px]'}
            type={'primary'}
            htmlType={'submit'}
          >
            수정
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default EditNoticeForm
