import { adminAxios } from 'config/adminClientConfig'

import { Address } from 'type/client/type'

const search = async (keyword: string) =>
  adminAxios.get<Address[]>(`/search/address?keyword=${keyword}`)

export const addressApi = {
  search,
}
