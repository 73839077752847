import { FormOutlined, HomeOutlined, TeamOutlined } from '@ant-design/icons'
import { Layout, Menu } from 'antd'
import { RoutePath } from 'enum/RoutePath'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

import { Logo } from './MainLogo'

import type { MenuProps } from 'antd'

const { Sider } = Layout

type MenuItem = Required<MenuProps>['items'][number]

const items: MenuItem[] = [
  {
    label: <NavLink to={RoutePath.Notice}>공지사항 관리</NavLink>,
    key: 'notice-management',
    icon: <FormOutlined />,
  },
  {
    label: <NavLink to={RoutePath.Terms}>약관 관리</NavLink>,
    key: 'terms-management',
    icon: <FormOutlined />,
  },
  {
    label: '가게',
    icon: <HomeOutlined />,
    key: 'store',
    children: [
      {
        label: <NavLink to={RoutePath.Store}>가게 관리</NavLink>,
        key: 'store-management',
      },
      {
        label: (
          <NavLink to={RoutePath.StoreRegistration}>
            가게 등록 요청 관리
          </NavLink>
        ),
        key: 'store-registration-management',
      },
    ],
  },
  {
    label: <NavLink to={RoutePath.Lounge}>라운지 관리</NavLink>,
    key: 'lounge-management',
    icon: <TeamOutlined />,
  },
  {
    label: <NavLink to={RoutePath.Sticker}>스티커 관리</NavLink>,
    key: 'sticker-management',
    icon: <TeamOutlined />,
  },
]

const SideMenu = () => {
  const [collapsed, setCollapsed] = useState(false)

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={value => setCollapsed(value)}
    >
      <Logo />
      <Menu
        theme={'dark'}
        mode="inline"
        items={items}
      />
    </Sider>
  )
}

export default SideMenu
