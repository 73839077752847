import { Button, Form, Input, message, Space } from 'antd'
import React from 'react'

import { useForm } from 'antd/lib/form/Form'

import { storeRegistrationApi } from 'feature/store-registration/api'
import { AddressInput } from 'feature/ui/components/form/AddressInput'

import {
  StoreRegistration,
  UpdateStoreRegistrationPayload,
} from 'type/client/storeRegistration'

const UpdateStoreRegistrationForm = ({
  storeRegistration,
  onFormFinish,
}: {
  storeRegistration: StoreRegistration
  onFormFinish: () => void
}) => {
  const [form] = useForm()

  const initialFormValues: UpdateStoreRegistrationPayload = {
    storeName: storeRegistration.storeName,
    address: storeRegistration.address,
  }

  const handleSubmitBtnClick = async (
    values: UpdateStoreRegistrationPayload
  ) => {
    try {
      await storeRegistrationApi.update(storeRegistration.id, values)
      onFormFinish()
    } catch (e) {
      message.error('가게 수정에 실패했습니다.')
    }
  }

  return (
    <Form
      form={form}
      layout={'vertical'}
      onFinish={handleSubmitBtnClick}
      initialValues={initialFormValues}
    >
      <Form.Item
        label="가게명"
        name="storeName"
        rules={[
          {
            required: true,
            message: '가게명을 입력해주세요',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="주소"
        name="address"
        rules={[
          {
            required: true,
            message: '주소를 입력해주세요',
          },
        ]}
      >
        <AddressInput />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button
            className={'mt-3 w-[100px]'}
            type={'primary'}
            htmlType={'submit'}
          >
            수정
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default UpdateStoreRegistrationForm
