import { UploadOutlined } from '@ant-design/icons'
import { Button, Form, Input, message, Space, Upload, UploadFile } from 'antd'
import React from 'react'

import { useForm } from 'antd/lib/form/Form'

import { uploadApi } from 'feature/core/api/upload'
import { storeApi } from 'feature/store/api'
import { AddressInput } from 'feature/ui/components/form/AddressInput'
import { normalizeUploadFormItem } from 'feature/ui/util/upload'

import { Address, Location } from 'type/client/type'
import { S3ResourceType } from 'type/client/upload'

interface CreateStoreFormProps {
  name: string
  address: Address | null
  location: Location
  logoImageFiles: UploadFile[]
  phoneNumber: string
}

const CreateStoreForm = ({ onFormFinish }: { onFormFinish: () => void }) => {
  const [form] = useForm()

  const initialFormValues: CreateStoreFormProps = {
    name: '',
    address: null,
    location: {} as Location,
    logoImageFiles: [] as UploadFile[],
    phoneNumber: '',
  }

  const handleSubmitBtnClick = async (values: CreateStoreFormProps) => {
    try {
      let logoImagePath = null
      if (values.logoImageFiles.length > 0) {
        logoImagePath = await uploadApi.uploadTempFile(
          S3ResourceType.STORE_LOGO_IMAGE,
          values.logoImageFiles[0].originFileObj!
        )
      }

      await storeApi.create({
        name: values.name,
        address: values.address!,
        phoneNumber: values.phoneNumber,
        logoImagePath: logoImagePath,
      })

      onFormFinish()
    } catch (e) {
      message.error('가게 추가에 실패했습니다.')
    }
  }

  return (
    <Form
      form={form}
      layout={'vertical'}
      onFinish={handleSubmitBtnClick}
      initialValues={initialFormValues}
    >
      <Form.Item
        label="가게명"
        name="name"
        rules={[
          {
            required: true,
            message: '가게명을 입력해주세요',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="주소"
        name="address"
        rules={[
          {
            required: true,
            message: '주소를 입력해주세요',
          },
        ]}
      >
        <AddressInput />
      </Form.Item>
      <Form.Item
        label="로고 이미지"
        name="logoImageFiles"
        valuePropName="fileList"
        getValueFromEvent={normalizeUploadFormItem}
      >
        <Upload
          maxCount={1}
          beforeUpload={() => false}
          listType="picture"
        >
          <Button icon={<UploadOutlined />}>업로드</Button>
        </Upload>
      </Form.Item>
      <Form.Item
        label="전화번호"
        name="phoneNumber"
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button
            className={'mt-3 w-[100px]'}
            type={'primary'}
            htmlType={'submit'}
          >
            추가
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default CreateStoreForm
