import { UploadFile } from 'antd'

import { RcFile } from 'antd/lib/upload'

export const normalizeUploadFormItem = (
  e: RcFile[] | { fileList: RcFile[] }
) => {
  if (Array.isArray(e)) {
    return e
  }
  return e?.fileList
}

export const defaultImageUploadFile = (
  imagePath: string,
  imageUrl: string
): UploadFile => ({
  uid: '-1',
  name: imagePath,
  status: 'done',
  url: imageUrl,
})
