import { RoutePath } from 'enum/RoutePath'
import React from 'react'

export const Logo = () => (
  <div className={'text-center'}>
    <a
      href={RoutePath.Home}
      className="flex items-center"
    >
      <div className={'bg-[#324453] w-full h-8 m-3 rounded'} />
      {/*<img /> 로고 */}
    </a>
  </div>
)
