import { EditFilled } from '@ant-design/icons'
import { App, Modal } from 'antd'
import React from 'react'

import CreateLoungeForm from 'feature/lounge/componont/CreateLoungeForm'

const useCreateLoungeFormModal = () => {
  const { modal } = App.useApp()

  const handleFormFinish = (onChangeLounge: () => void) => {
    return () => {
      Modal.destroyAll()
      onChangeLounge()
    }
  }

  const open = (onChangeLounge: () => void) =>
    modal.confirm({
      title: '라운지 생성',
      icon: <EditFilled />,
      content: (
        <CreateLoungeForm onFormFinish={handleFormFinish(onChangeLounge)} />
      ),
      footer: null,
      closable: true,
      maskClosable: true,
      centered: true,
    })

  return {
    open,
  }
}

export default useCreateLoungeFormModal
