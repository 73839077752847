import { Button, Col, message, Row } from 'antd'
import React, { useEffect, useState } from 'react'

import { loungeApi } from 'feature/lounge/api'
import LoungeTable from 'feature/lounge/componont/LoungeTable'
import useCreateLoungeFormModal from 'feature/lounge/hook/useCreateLoungeFormModal'

import { Lounge } from 'type/client/lounge'

const LoungePage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [lounges, setLounges] = useState<Lounge[]>([])

  const { open: openCreateModal } = useCreateLoungeFormModal()

  useEffect(() => {
    loadLounges()
  }, [])

  const loadLounges = async () => {
    setIsLoading(true)
    try {
      const response = await loungeApi.getAll()
      setLounges(response)
    } catch (error) {
      message.error('라운지 목록을 불러오는데 실패했습니다.')
    } finally {
      setIsLoading(false)
    }
  }

  const handleCreateBtnClick = () => {
    openCreateModal(loadLounges)
  }

  return (
    <>
      <Row>
        <div className={'text-2xl font-bold mb-3'}>라운지 관리</div>
      </Row>
      <Row className={'mb-6'}>
        <Col span={24}>
          <Button
            type={'primary'}
            onClick={handleCreateBtnClick}
          >
            라운지 추가
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <LoungeTable
            isLoading={isLoading}
            lounges={lounges}
            loadLounges={loadLounges}
          />
        </Col>
      </Row>
    </>
  )
}

export default LoungePage
