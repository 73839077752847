import { Button, Form, Input, message, Space } from 'antd'
import React from 'react'

import { useForm } from 'antd/lib/form/Form'

import { storeRegistrationApi } from 'feature/store-registration/api'

import { StoreRegistration } from 'type/client/storeRegistration'

const RejectStoreRegistrationForm = ({
  storeRegistrations,
  onFormFinish,
}: {
  storeRegistrations: StoreRegistration[]
  onFormFinish: () => void
}) => {
  const [form] = useForm()

  const initialFormValues = {
    reason: '',
  }

  const handleSubmitBtnClick = async ({ reason }: { reason: string }) => {
    try {
      const ids = storeRegistrations.map(store => store.id)

      await storeRegistrationApi.reject({
        ids,
        reason,
      })
      onFormFinish()
    } catch (e) {
      message.error('가게 등록 거절에 실패했습니다.')
    }
  }

  return (
    <Form
      form={form}
      layout={'vertical'}
      onFinish={handleSubmitBtnClick}
      initialValues={initialFormValues}
    >
      <Form.Item
        label="사유"
        name="reason"
        rules={[
          {
            required: true,
            message: '사유를 입력해주세요',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button
            className={'mt-3 w-[100px]'}
            type={'primary'}
            htmlType={'submit'}
          >
            비활성화
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default RejectStoreRegistrationForm
