import ReactDOM from 'react-dom/client'

import 'index.css'

import { ConfigProvider } from 'antd'
import App from 'App'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'

import locale from 'antd/locale/ko_KR'

const theme = {
  token: {
    fontSize: 14,
  },
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <RecoilRoot>
    <ConfigProvider
      locale={locale}
      theme={theme}
    >
      <BrowserRouter>
        {/*<React.StrictMode>*/}
        <App />
        {/*</React.StrictMode>*/}
      </BrowserRouter>
    </ConfigProvider>
  </RecoilRoot>
)
